import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin, baseUrladminV2 } from "../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function ConfigureMessages() {
  const AccessToken = localStorage.getItem("accessToken");
  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState('');
  const { state } = useLocation();
  const [bal, setbal] = useState();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();
  // useEffect(() => {
  //   fetch(baseUrladmin + "balance/getCompanyBalance", {
  //     headers: {
  //       Authorization: `Bearer ${AccessToken}`,
  //       "Content-type": "application/json; charset=UTF-8",
  //       "companyId": localStorage.getItem("companyId"),
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setbal(data.data.balance);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // }, []);

  const onSubmit = (data) => {
    const bodyFormData = {
      deviceAddress: state.deviceAddress,
      deviceName: state.deviceName,
      cpm: data.cpm,
      sec: data.sec,
      bnr: data.bnr
    };
    axios({
      method: "post",
      url: baseUrladminV2 + "device/sendDeviceConfMessages",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((response) => response)
      .then((data) => {
        if (data.data.status == 200) {
          alert("successfully configured");
          navigate("/devicedata");
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={6}><Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Configure Messages
            </MDTypography>
          </MDBox>
          <div className='App container fpd col-6'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row'>
                <div className='mb-3'>
                  <label htmlFor='deviceAddress'>Device Address</label>
                  <input
                    className='form-control'
                    placeholder=''
                    type='text'
                    name='deviceAddress'
                    value={state.deviceAddress}
                    readOnly
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='deviceName'>Device Name</label>
                  <input
                    className='form-control'
                    placeholder='deviceName'
                    type='text'
                    name='deviceName'
                    value={state.deviceName}
                    readOnly
                  />
                </div>
              </div>
              <div className='row'>

                <div className='mb-3'>
                  <label htmlFor='cpm'>CPM</label>
                  <input
                    className='form-control'
                    placeholder='cpm'
                    type='text'
                    name='cpm'
                    {...register("cpm", {
                      required: true,
                      validate: value => value === '1' || value === '2' || 'CPM must be 1 or 2'
                    })}
                  />
                  {errors.cpm && <span className="text-danger">{errors.cpm.message}</span>}
                </div>

                <div className='mb-3'>
                  <label htmlFor='amount'>SectorKey</label>
                  <input
                    className={`form-control ${errors.sec ? 'is-invalid' : ''}`}
                    placeholder='Amount'
                    type='number'
                    name='sec'
                    {...register("sec", {
                      required: true,
                      pattern: {
                        value: /^[0-9a-fA-F]{12}$/,
                        message: "SectorKey must be exactly 12 hex characters",
                      }
                    })}
                  />
                 {errors.sec && <span className="text-danger">{errors.sec.message}</span>}

                </div>
              </div>
              <div className='row'>

                <div className='mb-3'>
                  <label htmlFor='notes'>Banner</label>
                  <input
                    className='form-control'
                    placeholder='Notes'
                    type='text'
                    name='bnr'
                    {...register("bnr", { required: true, min: 100, max: 200000  })}
                  />
                </div>
              </div>
              <div className='mb-2'>
                <MDButton variant="outlined" type="submit" color="info" size="small">
                  Configure
                </MDButton>
              </div>
            </form>

          </div>
          <div className='mb-3'>
          </div>
        </Card>
      </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default ConfigureMessages;
