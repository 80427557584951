import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Companytypes from "layouts/tables/companyTypes";
import Companies from "layouts/tables/companies";
import Devices from "layouts/tables/devices";
import Messages from "layouts/tables/messages";
import POSHistory from "layouts/tables/poshistory";
import DisHistory from "layouts/tables/dishistory";
import BalHistory from "layouts/tables/balhistory";
import PhoneStatusHistory from "layouts/tables/phonestatushistory";
import SendDevicedownlinkData from "layouts/tables/SendDownlinkdata";
import AddDevice from "layouts/tables/device/adddevice";
import Devicedata from "layouts/tables/devicedata";
import Balancetransfer from "layouts/tables/baltransfer";
import Transactionreport from "layouts/tables/transactionreport";
// @mui icons
import Icon from "@mui/material/Icon";
import DevicesDevicedownlinkData from "layouts/tables/devicesdownlinkdata";
import Customers from "layouts/tables/customer/customer";
import AddCustomer from "layouts/tables/customer/addcustomer";
import EditDevice from "layouts/tables/device/editdevice";
import EditCustomer from "layouts/tables/customer/editcustomer";
import AddUser from "layouts/tables/users/adduser";
import Unknownphonestatus from "layouts/tables/unknownphonestatus/unknownphonestatus";
import POSMobiles from "layouts/tables/posmobiles/posmobiles";
import Selftransfer from "layouts/tables/selftransfer";
import Phonerechargealert from "layouts/tables/phonerechargealert";
import MyTasks from "layouts/tables/mytasks";
import AddTask from "layouts/tables/task/addtask";
import EditTask from "layouts/tables/task/edittask";
import LowSignalDevices from "layouts/tables/lowsignal";
import CampusManager from "layouts/tables/campus";
import UserManagement from "layouts/tables/userManagement";
import EditUser from "layouts/tables/users/editUser";
import ConfigureMessages from "layouts/tables/configureMessages"
// Fetch roles from localStorage
const userRole = localStorage.getItem("roles");

// Define all routes
const allRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Company Types",
    key: "cttables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/companytypes",
    component: <Companytypes />,
  },
  {
    type: "collapse",
    name: "Companies",
    key: "companytables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/companies",
    component: <Companies />,
  },
  {
    type: "collapse",
    name: "Devices",
    key: "devtables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/devices",
    component: <Devices />,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/messages",
    component: <Messages />,
  },
  {
    type: "collapse",
    name: "POSHistory",
    key: "poshistory",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/poshistory",
    component: <POSHistory />,
  },
  {
    type: "collapse",
    name: "DISHistory",
    key: "dishistory",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/dishistory",
    component: <DisHistory />,
  },
  {
    type: "collapse",
    name: "BalHistory",
    key: "balhistory",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/balhistory",
    component: <BalHistory />,
  },

  {
    type: "collapse",
    name: "PhoneStatusHistory",
    key: "phonestatushistory",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/phonestatushistory",
    component: <PhoneStatusHistory />,
  },
  {
    type: "collapse",
    name: "Device Schedule",
    key: "Device Schedule",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/DeviceSchedule",
    component: <DevicesDevicedownlinkData />,
  },
  {
    type: "collapse",
    name: "Downlink Data",
    key: "DownlinkData",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/downlinkdata",
    component: <SendDevicedownlinkData />,
  },
  {
    type: "collapse",
    name: "configure Messages",
    key: "configureMessages",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/deviceConfigureMessages",
    component: <ConfigureMessages />,
  },
  {
    type: "collapse",
    name: "Add Device",
    key: "adddevice",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/adddevice",
    component: <AddDevice />,
  },
  {
    type: "collapse",
    name: "View Device",
    key: "viewdevice",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/devicedata",
    component: <Devicedata />,
  },
  {
    type: "collapse",
    name: "Balance Transfer",
    key: "Balance Transfer",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/balancetransfer",
    component: <Balancetransfer />,
  },
  {
    type: "collapse",
    name: "Transaction Report",
    key: "Transaction Report",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/transactionreport",
    component: <Transactionreport />,
  },
  {
    type: "collapse",
    name: "MyTasks",
    key: "MyTaskst",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/mytasks",
    component: <MyTasks />,
  },
  {
    type: "collapse",
    name: "Low Signal Phones",
    key: "Low Signal Phones",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/lowsignalphones",
    component: <LowSignalDevices />,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "Customers",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/customers",
    component: <Customers />,
  },
  {
    type: "collapse",
    name: "AddCustomer",
    key: "AddCustomer",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/addCustomer",
    component: <AddCustomer />,
  },
  {
    type: "collapse",
    name: "EditDevice",
    key: "EditDevice",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/editDevice",
    component: <EditDevice />,
  },
  {
    type: "collapse",
    name: "EditCustomer",
    key: "EditCustomer",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/EditCustomer",
    component: <EditCustomer />,
  },
  {
    type: "collapse",
    name: "AddUser",
    key: "AddUser",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/AddUser",
    component: <AddUser />,
  },
  {
    type: "collapse",
    name: "Unknownphonestatus",
    key: "Unknownphonestatus",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/Unknownphonestatus",
    component: <Unknownphonestatus />,
  },
  {
    type: "collapse",
    name: "POS Mobiles",
    key: "POSMobiles",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/POSMobiles",
    component: <POSMobiles />,
  },
  {
    type: "collapse",
    name: "Selftransfer",
    key: "Selftransfer",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/Selftransfer",
    component: <Selftransfer />,
  },
  {
    type: "collapse",
    name: "Phonerechargealert",
    key: "Phonerechargealert",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/Phonerechargealert",
    component: <Phonerechargealert />,
  },
  {
    type: "collapse",
    name: "Add Task",
    key: "addtask",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/addTask",
    component: <AddTask />,
  },
  {
    type: "collapse",
    name: "Campus  Management",
    key: "campusManagement",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/campus-management",
    component: <CampusManager />,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "userManagement",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/user-management",
    component: <UserManagement />,
  },
  {
    type: "collapse",
    name: "Add User",
    key: "addUser",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/addUser",
    component: <AddUser />,
  },
  {
    type: "collapse",
    name: "Edit User",
    key: "editUser",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/editUser",
    component: <EditUser />,
  },
];

// Filter routes based on role
const routes =
  userRole === "ROLE_ADMIN"
    ? allRoutes // If ROLE_ADMIN, return all routes
    : allRoutes.filter(
        (route) => route.key === "dashboard" || route.key === "sign-in"
      ); // Else, return only the "Dashboard" route

export default routes;
